.slider {
    position: relative;
    height: 5vh;
    display: flex;
    
    justify-content: center;
    align-items: center;
}

.image  {
    /* width: 530px;
    height: 250px; */
    /* border-radius: 10px; */
    width: 170px;
    height: 55px;
    
}

.imageL {
    width: 550px;
    height: 225px;
}

.right-arrow {
    position: absolute;
    right: 0px;
    font-size: 3rem;
    color: #000;
    z-index: 9;
    cursor: pointer;
    user-select: none;
}

.left-arrow {
    position: absolute;
    
    left: 0px;
    font-size: 3rem;
    color: #000;
    z-index: 9;
    cursor: pointer;
    user-select: none;
}

.slide {
    opacity: 0;
    transition-duration: 1s ease;
}

.slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
}

.slideAll {
    opacity: 1;
    padding: 15px;

    transition-duration: 1s;
    transform: scale(1.08);
}  