@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slider2 {
  width: 100%;
  margin: 0rem auto;
  height: 250px;
}

.slide2 img {
  width: 10rem;
  margin: 0 auto;
}

.slide2 {
  transform: scale(1.0);
  transition: transform 300ms;
  opacity: 1;
}

.activeSlide2 {
  transform: scale(1.1);
  opacity: 1;
}

.arrow {
    position: absolute;
    right: 32px;
    font-size: 3rem;
    color: #000;
    z-index: 9;
    cursor: pointer;
    user-select: none;
}

.arrow svg {
  transition: color 300ms;
}


.next {
  right: 0%;
  top: 5%;
}

.prev {
  left: 0%;
  top: 5%;
}